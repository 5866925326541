import React from 'react';
import { Form, FormItem, Input, InputNumber, ResetButton, Select, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import { Col, DatePicker, Row, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { getPredefinedTapsValues, validationSchema } from './FormFeedsFilter.const';
import { FEEDS_SOURCE_MAP } from '../../pages/Feeds/PageFeeds.const';
import { DATE_FORMAT } from '../../../../const/system';
import useDatepickerHandler from '../../../../app/hooks/useDatepickerHandler';
import useCategoriesOptions from '../../../../app/hooks/useCategoriesOptions';
import makeSelectFilterOption from '../../../../lib/makeSelectFilterOption';
import Spinner from '../../../../components/Spinner';
import { useGetCategoriesQuery } from '../../api/categoriesApiSlice';
import { useGetTagsQuery } from '../../api/tagsApiSlice';



const { Option } = Select;

const FormFeedsFilter = ({
  feedSource,
  initialValues,
  onSubmit,
  onCancel,
  isSubmitting,
  formErrors,
}) => {
  const intl = useIntl();
  const { onBlur } = useDatepickerHandler();

  const { data: categories = { data: [] }, isLoading: isCategoriesLoading } = useGetCategoriesQuery({ queryParams: 'limit=0' });
  const { data: hashtags = { data: [] }, isLoading: isTagsLoading } = useGetTagsQuery({ queryParams: 'limit=0' });

  const categoryOptions = useCategoriesOptions(categories?.data);
  const feedsOptions = JSON.parse(localStorage.getItem('feeds_options')) || {};
  const { formats = [], layouts = [] } = feedsOptions;

  if (isCategoriesLoading || isTagsLoading) {
    return (<div className="h-mt-6 text-bold"><IntlMessages id="content-image-filter-loading" /></div>);
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting
      initialErrors={formErrors}
      initialValues={initialValues}
      onSubmit={(values) => {
        const { is_main_category, ...restValues } = values;

        onSubmit(is_main_category === 0 ? restValues : values);
      }}
      onReset={onCancel}
    >
      {({ isValid, dirty, values, setFieldValue, setFieldTouched }) => {
        return (
          <Form
            className="images-filter w-full"
            layout="vertical"
          >
            <Spinner spinning={isSubmitting}>
              <Row gutter={16}>
                <Col span={12}>
                  <FormItem
                    name='id'
                    className="ant-form-item-col stretched"
                    label={
                      <Row className='w-full' justify="space-between" align="middle">
                        <IntlMessages id="ui-general-id" />
                        <Tooltip placement="topRight" title={<IntlMessages id="ui-general-filter-id-key-help" />}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Row>
                    }
                  >
                    <Input
                      name="id"
                      placeholder={intl.formatMessage({ id: 'ui-general-ids-placeholder' })}
                      disabled={!isEmpty(values.bee_id)}
                    />
                  </FormItem>
                </Col>

                <Col span={12}>
                  <FormItem
                    name='bee_id'
                    className="ant-form-item-col stretched"
                    label={
                      <Row className='w-full' justify="space-between" align="middle">
                        <IntlMessages id="ui-general-bee-id" />
                        <Tooltip placement="topRight" title={<IntlMessages id="features-filter-bee-id-key-help" />}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Row>
                    }
                  >
                    <Input
                      name="bee_id"
                      placeholder={intl.formatMessage({ id: 'content-images-folder-name-placeholder' })}
                      disabled={!isEmpty(values.id)}
                    />
                  </FormItem>
                </Col>

                <Col span={24}>
                  <FormItem
                    label={intl.formatMessage({ id: 'content-images-category-label' })}
                    name="categories"
                    className="hp-mb-8"
                  >
                    <Select
                      name="categories"
                      mode="multiple"
                      allowClear
                      showSearch
                      placeholder={intl.formatMessage({ id: 'ui-general-choose-categories' })}
                      options={categoryOptions}
                      loading={isCategoriesLoading}
                      filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                    />
                  </FormItem>

                  <FormItem
                    label={intl.formatMessage({ id: 'content-images-only-main-category-label' })}
                    name="is_main_category"
                    style={{ flexDirection: 'row-reverse', alignItems: 'end' }}
                  >
                    <Input
                      checked={values?.is_main_category ?? false}
                      id="is_main_category"
                      type="checkbox"
                      name="is_main_category"
                      onChange={async (event) => {
                        await setFieldValue('is_main_category', Number(event.target.checked), true);
                      }}
                    />
                  </FormItem>
                </Col>

                {feedSource === FEEDS_SOURCE_MAP.RELEASED && (
                  <Col span={24}>
                    <FormItem
                      label={intl.formatMessage({ id: 'content-images-layout-label' })}
                      name="layouts"
                    >
                      <Select
                        mode="multiple"
                        name="layouts"
                        allowClear
                        showSearch
                        placeholder={intl.formatMessage({ id: 'content-images-layout-label-placeholder' })}
                        filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                        options={layouts.filter((item) => item !== 'other').map((item) => {
                          return {
                            label: capitalize(item),
                            value: item,
                          };
                        })}
                      />
                    </FormItem>
                  </Col>
                )}

                <Col span={24}>
                  <FormItem
                    label={intl.formatMessage({ id: 'content-images-hashtags-label' })}
                    name="hashtags"
                  >
                    <Select
                      name="hashtags"
                      mode="multiple"
                      allowClear
                      showSearch
                      placeholder={intl.formatMessage({ id: 'content-images-hashtags-label-placeholder' })}
                      loading={isTagsLoading}
                      options={hashtags.data.map((item) => {
                        return {
                          label: `#${item.tag}`,
                          value: item.id,
                        };
                      })}
                      filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                    />
                  </FormItem>
                </Col>

                <Col span={24}>
                  <FormItem
                    label={intl.formatMessage({ id: 'content-images-format-label' })}
                    name="formats"
                  >
                    <Select
                      name="formats"
                      mode="multiple"
                      allowClear
                      showSearch
                      placeholder={intl.formatMessage({ id: 'content-images-format-label-placeholder' })}
                      filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                      options={formats.map((item) => {
                        return {
                          label: capitalize(item),
                          value: item,
                        };
                      })}
                    />
                  </FormItem>
                </Col>

                {![ FEEDS_SOURCE_MAP.ARCHIVED, FEEDS_SOURCE_MAP.TRASHED ].includes(feedSource) && (
                  <>
                    <Col span={12}>
                      <FormItem
                        label={intl.formatMessage({ id: 'content-images-released-from-label' })}
                        name='released_from'
                      >
                        <DatePicker
                          name="imported_from"
                          value={values?.released_from ? moment(values.released_from, DATE_FORMAT) : null}
                          onChange={(date, dateString) => {
                            setFieldValue('released_from', dateString);
                          }}
                          onBlur={async ({ target }) => {
                            await setFieldTouched('released_from', true);
                            await onBlur('released_from', target.value, setFieldValue);
                          }}
                        />
                      </FormItem>
                    </Col>

                    <Col span={12}>
                      <FormItem
                        label={intl.formatMessage({ id: 'content-images-released-to-label' })}
                        name='released_to'
                      >
                        <DatePicker
                          name="released_to"
                          value={values?.released_to ? moment(values.released_to, DATE_FORMAT) : null}
                          onChange={(date, dateString) => {
                            setFieldValue('released_to', dateString);
                          }}
                          onBlur={async ({ target }) => {
                            await setFieldTouched('released_to', true);
                            await onBlur('released_to', target.value, setFieldValue);
                          }}
                        />
                      </FormItem>
                    </Col>
                  </>
                )}

                <Col span={6}>
                  <FormItem
                    label={intl.formatMessage({ id: 'content-images-taps-from-label' })}
                    name='taps_from'
                  >
                    <InputNumber
                      min={0}
                      value={values?.taps_from ? Number(values?.taps_from) : null}
                      name="taps_from"
                      onChange={() => {
                        setFieldValue('taps_difficulty', null);
                      }}
                      className="w-full"
                      placeholder={intl.formatMessage({ id: 'ui-general-min' })}
                    />
                  </FormItem>
                </Col>

                <Col span={6}>
                  <FormItem
                    label={intl.formatMessage({ id: 'content-images-taps-to-label' })}
                    name='taps_to'
                  >
                    <InputNumber
                      min={0}
                      value={values?.taps_to ? Number(values?.taps_to) : null}
                      name="taps_to"
                      onChange={() => {
                        setFieldValue('taps_difficulty', null);
                      }}
                      className="w-full"
                      placeholder={intl.formatMessage({ id: 'ui-general-max' })}
                    />
                  </FormItem>
                </Col>

                <Col span={6}>
                  <FormItem
                    label={intl.formatMessage({ id: 'content-images-colors-from-label' })}
                    name='colors_from'
                  >
                    <InputNumber
                      min={0}
                      value={values?.colors_from ? Number(values?.colors_from) : null}
                      name="colors_from"
                      className="w-full"
                      placeholder={intl.formatMessage({ id: 'ui-general-min' })}
                    />
                  </FormItem>
                </Col>

                <Col span={6}>
                  <FormItem
                    label={intl.formatMessage({ id: 'content-images-colors-to-label' })}
                    name='colors_to'
                  >
                    <InputNumber
                      min={0}
                      value={values?.colors_to ? Number(values?.colors_to) : null}
                      name="colors_to"
                      className="w-full"
                      placeholder={intl.formatMessage({ id: 'ui-general-max' })}
                    />
                  </FormItem>
                </Col>

                <Col span={24}>
                  <FormItem
                    name="taps_difficulty"
                    className="ant-form-item-col stretched"
                    label={
                      <Row className='w-full' justify="space-between" align="middle">
                        <IntlMessages id="content-images-taps-difficulty-label" />
                        <Tooltip placement="topRight" title={<IntlMessages id="feeds-form-difficulty-tooltip" />}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Row>
                    }
                  >
                    <Select
                      name="taps_difficulty"
                      allowClear
                      placeholder={intl.formatMessage({ id: 'content-images-taps-difficulty-label-placeholder' })}
                      loading={isTagsLoading}
                      onChange={(value) => {
                        const defaultTaps = getPredefinedTapsValues(value);

                        setFieldValue('taps_from', defaultTaps.taps_from);
                        setFieldValue('taps_to', defaultTaps.taps_to);
                        setFieldTouched('taps_from', true);
                        setFieldTouched('taps_to', true);
                      }}
                      filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                    >
                      <Option value='very_simple'>
                        <IntlMessages id="feeds-form-very-simple-difficulty" />
                        &nbsp;
                        (1-299)
                      </Option>
                      <Option value='simple'>
                        <IntlMessages id="feeds-form-simple-difficulty" />
                        &nbsp;
                        (1-699)
                      </Option>
                      <Option value='medium'>
                        <IntlMessages id="feeds-form-medium-difficulty" />
                        &nbsp;
                        (700-999)
                      </Option>
                      <Option value='hard'>
                        <IntlMessages id="feeds-form-hard-difficulty" />
                        &nbsp;
                        (1000-&infin;)
                      </Option>
                    </Select>
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={[ 16, 16 ]} justify='end' className='hp-mt-32'>
                <Col>
                  <ResetButton disabled={false} loading={isSubmitting}>
                    <IntlMessages id='ui-general-reset' />
                  </ResetButton>
                </Col>

                <Col>
                  <SubmitButton
                    loading={isSubmitting}
                    disabled={!isValid || !dirty}
                  >
                    <IntlMessages id="ui-general-apply" />
                  </SubmitButton>
                </Col>
              </Row>
            </Spinner>
          </Form>
        );
      }}
    </Formik>
  );
};

FormFeedsFilter.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  feedSource: PropTypes.string.isRequired,
  formErrors: PropTypes.object,
};

export default FormFeedsFilter;
