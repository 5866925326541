import { useState, useEffect, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSearchParams } from 'react-router-dom';
import { excludedFilterParseFields, isUsedSearch } from './useQueryParams';
import encodeUnsafeCharacters from '../../lib/encodeUnsafeCharacters';
import decodeUnsafeCharacters from '../../lib/decodeUnsafeCharacters';
import parseQueryParam from '../../lib/parseQueryParam';
import useDebounce from './useDebounce';
import getQueryParams from '../../lib/getQueryParams';
import makeObjectToQueryString from '../../lib/makeObjectToQueryString';
import { groupDataByDate } from '../../domains/Content/pages/Feeds/PageFeeds.const';



const useInfiniteScroll = ({
  data = [],
  pagination = {},
  searchFields,
  source = '',
  isRefetch = false,
}) => {
  const [ resetIsManuallyTriggered, setResetManuallyTriggered ] = useState(false);
  const [ groupedItemsList, setGroupedItemsList ] = useState({});
  const [ itemsArray, setItemsArray ] = useState([]);
  const [ itemsLength, setItemsLength ] = useState(0);
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ searchTerm, setSearchTerm ] = useState('');
  const [ initFilterValues, setInitFilterValues ] = useState({});
  const [ combinedQueryParams, setCombinedQueryParams ] = useState({});

  const queryParams = getQueryParams(window.location.search);
  const searchTermQuery = useDebounce(searchTerm.trim());
  const isSearch = isUsedSearch(queryParams, searchFields);
  const prevSearchParamsRef = useRef(searchParams);
  const prevSourceRef = useRef(source);

  const prevSource = prevSourceRef.current;
  const prevSearchParams = prevSearchParamsRef.current;
  const hasSearchParamsChanged = !prevSearchParams || prevSearchParams.toString() !== searchParams.toString();
  const scrollableDiv = document.getElementById('scrollableDiv');
  const { current_page, has_more } = pagination;


  const resetItems = () => {
    setGroupedItemsList({});
    setItemsArray([]);
    setItemsLength(0);
  };

  useEffect(() => {
    if (hasSearchParamsChanged || !isEmpty(searchParams.toString()) || isRefetch) {
      setCombinedQueryParams(makeObjectToQueryString(Object.assign(Object.fromEntries(searchParams.entries()), { page: 1 })));
      resetItems();
    }

    prevSearchParamsRef.current = searchParams;
  }, [ searchParams, isRefetch ]);


  // Set initFilterValues as default
  useEffect(() => {
    if (!isEmpty(initFilterValues)) {
      const searchQuery = Object.entries(initFilterValues)
        .map(([ key, value ]) => `${key}:${encodeUnsafeCharacters(value)}`)
        .join(';');

      setSearchParams({ ...queryParams, search: searchQuery }, { replace: true });
    }
  }, [ initFilterValues ]);

  // Reset
  useEffect(() => {
    if (resetIsManuallyTriggered) {
      if (prevSource !== source || hasSearchParamsChanged) {
        resetItems();
      }

      setSearchParams({}, { replace: true });
      setInitFilterValues({});
      setSearchTerm('');

      if (scrollableDiv) {
        scrollableDiv.scrollTop = 0;
      }
    }
  }, [ resetIsManuallyTriggered ]);


  // Reset data on source change
  useEffect(() => {
    if (prevSource !== source) {
      resetItems();
      setCombinedQueryParams({});
      setInitFilterValues({});
      prevSourceRef.current = source;

      if (scrollableDiv) {
        scrollableDiv.scrollTop = 0;
      }
    }
  }, [ source ]);


  // Filter
  useEffect(() => {
    if (queryParams.search) {
      queryParams.search.split(';').forEach((item) => {
        const [ key, value ] = item.split(':');

        if (value !== '') {
          const decodedValue = decodeUnsafeCharacters(value);

          if (isSearch && !searchTermQuery.length) {
            setSearchTerm(decodedValue);
          }
          initFilterValues[key] = !excludedFilterParseFields.includes(key) ?
            parseQueryParam(decodedValue) : decodedValue;
        }
      });

      setInitFilterValues(initFilterValues);
      setSearchParams(queryParams, { replace: true });
    }
  }, [ initFilterValues ]);

  // Search
  useEffect(() => {
    const newParams = { ...queryParams };

    Array.from(searchParams.entries()).forEach(([ key, value ]) => {
      if ((!searchTermQuery.length && key === 'search') || key.endsWith('[]')) {
        return;
      }

      newParams[key] = value;
    });

    if (searchTermQuery.length && searchFields?.length) {
      newParams.search = searchFields
        .map((field) => `${field}:${encodeUnsafeCharacters(searchTermQuery)}`)
        .join(';');
      setSearchParams(newParams, { replace: true });
    }

    if (isSearch && !searchTermQuery.length) {
      const { search, ...restParams } = newParams;

      setSearchParams(restParams, { replace: true });
    }
    setInitFilterValues({});
  }, [ searchTermQuery ]);


  useEffect(() => {
    if (data.length !== 0) {
      setItemsArray((prevData = []) => {
        const arrData = prevData.concat(data);

        setGroupedItemsList(groupDataByDate(arrData, source));
        return arrData;
      });
      setItemsLength((prevLength) => prevLength + data.length);
    }
  }, [ data ]);


  const handleLoadNextData = () => {
    if (isEmpty(pagination) || !has_more) {
      return;
    }

    setTimeout(() => {
      const newOffsetQuery = makeObjectToQueryString({ page: current_page + 1 });

      setCombinedQueryParams((prevParams) => makeObjectToQueryString({
        ...getQueryParams(prevParams),
        ...getQueryParams(newOffsetQuery),
      }));
    }, 100);
  };

  return {
    itemsArray,
    groupedItemsList,
    itemsLength,
    hasMore: pagination?.has_more ?? false,
    handleLoadNextData,
    initFilterValues,
    setInitFilterValues,
    combinedQueryParams,
    searchParams,
    resetIsManuallyTriggered,
    setResetManuallyTriggered,
  };
};

export default useInfiniteScroll;
