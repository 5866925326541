import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Form, FormItem, Input, Select } from 'formik-antd';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import makeSelectFilterOption from '../../../../lib/makeSelectFilterOption';



const FormEditImage = ({ allCategories, allHashtags }) => {
  const intl = useIntl();

  const categoryOptions = allCategories?.map((category) => {
    return {
      value: category.id,
      label: `${category.name} (${intl.formatMessage({ id: 'content-images-category-client-id' })}: ${category.client_id})`,
    };
  }) ?? [];

  const hashtagsOptions = allHashtags?.map((tag) => {
    return {
      value: tag.id,
      label: `#${tag.tag}`,
    };
  }) ?? [];

  const feedsOptions = JSON.parse(localStorage.getItem('feeds_options')) || {};
  const allLabels = feedsOptions?.labels;

  const labelsOptions = allLabels?.map((label) => {
    return {
      value: label,
      label,
    };
  }) ?? [];

  return (
    <Form layout="vertical" className='update-image-modal-form'>
      <FormItem
        className='ant-form-item-col update-image-modal-form__form-item'
        name="main_category"
        label={intl.formatMessage({ id: 'content-images-main-category' })}
      >
        <Select
          allowClear
          showSearch
          filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
          size='small'
          name="main_category"
          options={categoryOptions}
          placeholder={<IntlMessages id="content-images-main-category-placeholder" />}
        />
      </FormItem>

      <FormItem
        className='ant-form-item-col update-image-modal-form__form-item'
        name="categories"
        label={intl.formatMessage({ id: 'ui-general-categories' })}
      >
        <Select
          mode="multiple"
          allowClear
          showSearch
          filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
          size='small'
          name="categories"
          options={categoryOptions}
          placeholder={<IntlMessages id="ui-general-choose-categories" />}
        />
      </FormItem>

      <FormItem
        className='ant-form-item-col update-image-modal-form__form-item'
        name="labels"
        label={intl.formatMessage({ id: 'ui-general-labels' })}
      >
        <Select
          mode="multiple"
          allowClear
          showSearch
          filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
          size='small'
          name="labels"
          options={labelsOptions}
          placeholder={<IntlMessages id="ui-general-choose-labels" />}
        />
      </FormItem>

      <FormItem
        className='ant-form-item-col update-image-modal-form__form-item'
        name="hashtags"
        label={intl.formatMessage({ id: 'ui-general-hashtags' })}
      >
        <Select
          mode="multiple"
          allowClear
          showSearch
          filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
          size='small'
          name="hashtags"
          options={hashtagsOptions}
          placeholder={<IntlMessages id="ui-general-choose-hashtags" />}
        />
      </FormItem>

      <FormItem
        className='ant-form-item-col update-image-modal-form__form-item'
        name="comment"
        label={intl.formatMessage({ id: 'ui-general-comment' })}
      >
        <Input.TextArea
          name="comment"
          placeholder={intl.formatMessage({ id: 'ui-general-specify-value' })}
          rows={2}
          showCount
          maxLength={255}
        />
      </FormItem>
    </Form>
  );
};

FormEditImage.propTypes = {
  allCategories: PropTypes.array.isRequired,
  allHashtags: PropTypes.array.isRequired,
};

export default FormEditImage;
