import React from 'react';
import { Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { HasRights } from '../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../const/permissions';
import ButtonDanger from '../../../../components/ButtonDanger';
import ButtonDraft from '../../../../components/ButtonDraft';
import ButtonPrimary from '../../../../components/ButtonPrimary';
import {
  CONTENT_TYPE_MAP,
  FEEDS_SOURCE_MAP,
  MODAL_MODE,
  UPDATED_IMAGE_STATUSES,
} from '../../pages/Feeds/PageFeeds.const';



const ImageModalCardFooterView = ({ data, handleUpdateImageStatus, handleModalMode, feedSource, isFeeds = false }) => {
  const getContent = () => {
    if (!isFeeds) {
      return HasRights([ Permissions.CONTENT.FEEDS.IMAGE.UPDATE ]) && (
        <ButtonPrimary
          key='edit'
          onClick={() => handleModalMode(MODAL_MODE.EDIT)}
        />
      );
    }

    switch (feedSource) {
      case FEEDS_SOURCE_MAP.RELEASED:
      case FEEDS_SOURCE_MAP.LIST:
        return (
          <>
            {HasRights([
              Permissions.CONTENT.FEEDS.IMAGE.SET_ARCHIVED,
              Permissions.CONTENT.FEEDS.IMAGE.SET_TRASHED,
              Permissions.CONTENT.FEEDS.IMAGE.SET_DRAFT,
            ]) && (data.source_type === CONTENT_TYPE_MAP.FEED || feedSource === FEEDS_SOURCE_MAP.LIST) && (
              <ButtonDanger
                className='feed-modal__button feed-modal__button--danger'
                disabled={data?.used_in_collections || data?.used_in_editor_choices}
                key='unpublish'
                onClick={() => handleModalMode(MODAL_MODE.UNPUBLISH)}
              />
            )}
            {HasRights([ Permissions.CONTENT.FEEDS.IMAGE.UPDATE ]) && (
              <ButtonPrimary
                key='edit'
                onClick={() => handleModalMode(MODAL_MODE.EDIT)}
              />
            )}
          </>
        );
      case FEEDS_SOURCE_MAP.ARCHIVED:
        return (
          <>
            {HasRights([ Permissions.CONTENT.FEEDS.IMAGE.SET_DRAFT ]) && (
              <Popconfirm
                title={<IntlMessages id='feeds-trash-modal-return-to-draft-confirm' />}
                onConfirm={() => handleUpdateImageStatus(UPDATED_IMAGE_STATUSES.DRAFT)}
                okText={<IntlMessages id='ui-general-yes' />}
                cancelText={<IntlMessages id='ui-general-no' />}
              >
                <ButtonDraft
                  key='draft'
                  className='feed-modal__button feed-modal__button--draft'
                />
              </Popconfirm>
            )}
            {HasRights([ Permissions.CONTENT.FEEDS.IMAGE.UPDATE ]) && (
              <ButtonPrimary
                key='edit'
                onClick={() => handleModalMode(MODAL_MODE.EDIT)}
              />
            )}
          </>
        );
      case FEEDS_SOURCE_MAP.TRASHED:
        return HasRights([ Permissions.CONTENT.FEEDS.IMAGE.SET_DRAFT ]) && (
          <Popconfirm
            title={<IntlMessages id='feeds-trash-modal-return-to-draft-confirm' />}
            onConfirm={() => handleUpdateImageStatus(UPDATED_IMAGE_STATUSES.DRAFT)}
            okText={<IntlMessages id='ui-general-yes' />}
            cancelText={<IntlMessages id='ui-general-no' />}
          >
            <ButtonDraft
              key='draft'
              className='feed-modal__button feed-modal__button--draft'
            />
          </Popconfirm>
        );
      default:
        return undefined;
    }
  };

  return getContent();
};

ImageModalCardFooterView.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.any,
    icon: PropTypes.object,
    preview: PropTypes.object,
    created_at: PropTypes.string,
    data: PropTypes.object,
    source: PropTypes.object,
    categories: PropTypes.object,
    used_in_collections: PropTypes.array,
    used_in_editor_choices: PropTypes.array,
  }),
  handleUpdateImageStatus: PropTypes.func.isRequired,
  handleModalMode: PropTypes.func.isRequired,
  feedSource: PropTypes.string.isRequired,
  isFeeds: PropTypes.bool,
};

export default ImageModalCardFooterView;
