import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Menu, Tag } from 'antd';
import isEmpty from 'lodash/isEmpty';
import navigation from '../../../../navigation/vertical';
import { useApp } from '../../../../app/context/AppContext';



const { SubMenu } = Menu;

const MenuItem = (props) => {
  // eslint-disable-next-line react/prop-types
  const { onClose } = props;
  const { userPermissions } = useApp();
  const menuVisibleItems = navigation.filter((menuItem) => userPermissions?.some((permission) => !isEmpty(menuItem) ? menuItem?.groupPermissions?.includes(permission) || menuItem.permission === permission : null));

  // Redux
  const customise = useSelector((state) => state.customise);

  // Location
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');

  // Menu

  const menuItem = menuVisibleItems.map((item, index) => {
    if (item.header) {
      // eslint-disable-next-line react/no-array-index-key
      return <Menu.ItemGroup key={index} title={item.header} />;
    }

    if (item.children) {
      return (
        <SubMenu key={item.id} icon={item.icon} title={item.title}>
          {item.children.map((itemChildren) => {

            const isVisible = userPermissions?.includes(itemChildren.permission);

            if (!isVisible) {
              return null;
            }

            if (!itemChildren.children) {
              return (
              // Level 2
                <Menu.Item
                  key={itemChildren.id}
                  onClick={onClose}
                >
                  <Link to={itemChildren.navLink}>{itemChildren.title}</Link>
                </Menu.Item>
              );
            }
            return (
            // Level 3
              <SubMenu key={itemChildren.id} title={itemChildren.title}>
                {itemChildren.children.map((childItem) => {
                  return (
                    <Menu.Item
                      key={childItem.id}
                      onClick={onClose}
                    >
                      <Link to={childItem.navLink}>{childItem.title}</Link>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            );
          })}
        </SubMenu>
      );
    }

    return (
    // Level 1
      <Menu.Item
        key={item.id}
        icon={item.icon}
        onClick={onClose}
        style={item.tag && { pointerEvents: 'none' }}
      >
        {item.tag ? (
          <a href="#123" className="hp-d-flex hp-align-items-center hp-d-flex-between">
            <span>{item.title}</span>
            <Tag className="hp-mr-0 hp-border-none hp-text-color-black-100 hp-bg-success-3 hp-border-radius-full hp-px-8" style={{ marginRight: -14 }}>{item.tag}</Tag>
          </a>
        ) : (
          <Link to={item.navLink}>{item.title}</Link>
        )}
      </Menu.Item>
    );

  });

  return (
    <Menu
      mode="inline"
      defaultOpenKeys={[
        splitLocation.length === 5 ? splitLocation[splitLocation.length - 3] : null,
        splitLocation[splitLocation.length - 2],
      ]}
      theme={customise.theme === 'light' ? 'light' : 'dark'}
      className="hp-bg-black-20 hp-bg-dark-90"
    >
      {menuItem}
    </Menu>
  );
};

export default MenuItem;
