import React from 'react';
import { Formik } from 'formik';
import { Form, FormItem, ResetButton, SubmitButton, Switch } from 'formik-antd';
import { Col, DatePicker, Row, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { DEFAULT_RELEASE_TIME } from '../../../Featured/forms/FormEditFeature/FormEditFeature.const';
import { COLLECTIONS_SOURCE } from '../../pages/Collections/PageCollections/PageCollections.const';
import { makeValidationSchema } from './UpdateCollectionImageModalFormValidationSchema.const';
import useDatepickerHandler from '../../../../app/hooks/useDatepickerHandler';
import handleResponse from '../../../../lib/handleResponse';
import getConfigurations from '../../../../lib/getConfigurations';
import Spinner from '../../../../components/Spinner';
import { imageStatuses } from '../../../../const/imageStatuses';
import { useUpdateCollectionImageMutation } from '../../api/collectionsApiSlice';
import { useAttachImageMutation } from '../../api/imagesApiSlice';



const UpdateCollectionImageModalForm = ({
  initialValues,
  imageId,
  collectionDetails,
  onModalClose = () => {},
  isImageAttaching = false,
}) => {
  const intl = useIntl();
  const { onBlur } = useDatepickerHandler();

  const [ updateCollectionImage, { isLoading: isUpdatingCollectionImage } ] = useUpdateCollectionImageMutation();
  const [ attachImage, { isLoading: isAttaching } ] = useAttachImageMutation();

  const collectionConfig = getConfigurations(COLLECTIONS_SOURCE);
  const useDatePickerTime = collectionConfig?.publish?.use_time ?? false;
  const hasReleaseRange = collectionConfig?.release_range ?? false;

  const handleSubmit = (values) => {
    if (isImageAttaching) {
      const data = {
        attached_to: collectionDetails?.id,
        type: 'collection',
        status: imageStatuses.pack,
        ...values,
      };

      attachImage({ id: imageId, payload: data })
        .unwrap()
        .then(() => onModalClose())
        .then(() => handleResponse('success', intl, 'collection-attach-success'))
        .catch((error) => handleResponse('error', intl, 'collection-attach-failed', [], error));
    } else {
      updateCollectionImage({ collectionId: collectionDetails?.id, imageId, values })
        .unwrap()
        .then(() => onModalClose())
        .then(() => handleResponse('success', intl, 'ui-general-updated-successfully'))
        .catch((error) => handleResponse('error', intl, 'ui-general-update-failed', [], error));
    }
  };

  return (
    <Formik
      validationSchema={makeValidationSchema(collectionConfig, collectionDetails)}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, { resetForm }) => {
        await handleSubmit(values);
        await resetForm();
      }}
    >
      {({ values, setFieldValue, setFieldTouched, isValid, handleReset }) => {
        return (
          <Form layout="vertical" className='update-image-modal-form'>
            <Spinner spinning={isUpdatingCollectionImage || isAttaching}>
              {hasReleaseRange ? (
                <>
                  <Col span={24}>
                    <FormItem
                      label={intl.formatMessage({ id: 'ui-general-released-at' })}
                      name='released_at'
                      required
                    >
                      <DatePicker
                        name='released_at'
                        showToday
                        showTime={useDatePickerTime
                          ? { defaultValue: moment(DEFAULT_RELEASE_TIME, 'HH:mm:ss') }
                          : false}
                        disabledDate={(date) => (!isEmpty(values?.released_to)
                          && date.isAfter(moment(values?.released_to)))
                          || date.isBefore(moment(collectionDetails?.released_at))}
                        value={values?.released_at ? moment(values.released_at).utc(false) : null}
                        onChange={async (date, dateString) => {
                          await setFieldValue('released_at', !isEmpty(dateString) ? moment(dateString).utc(true) : '');
                        }}
                        onBlur={async ({ target }) => {
                          await setFieldTouched('released_at', true);
                          await onBlur('released_at', !isEmpty(target.value) ? moment(target.value).utc(true) : '', setFieldValue);
                        }}
                      />
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem
                      label={intl.formatMessage({ id: 'ui-general-released-to' })}
                      name='released_to'
                    >
                      <DatePicker
                        name='released_to'
                        showTime={useDatePickerTime
                          ? { defaultValue: moment(DEFAULT_RELEASE_TIME, 'HH:mm:ss') }
                          : false}
                        disabledDate={(date) => (!isEmpty(values?.released_at) && date.isBefore(moment(values?.released_at)))
                        || date.isAfter(moment(collectionDetails?.released_to))}
                        value={values?.released_to ? moment(values.released_to).utc(false) : null}
                        onChange={async (date, dateString) => {
                          await setFieldValue('released_to', !isEmpty(dateString) ? moment(dateString).utc(true) : '');
                        }}
                        onBlur={async ({ target }) => {
                          await setFieldTouched('released_to', true);
                          await onBlur('released_to', !isEmpty(target.value) ? moment(target.value).utc(true) : '', setFieldValue);
                        }}
                      />
                    </FormItem>
                  </Col>
                </>
              ) : (
                <Col span={24}>
                  <FormItem
                    label={intl.formatMessage({ id: 'ui-general-released-at' })}
                    name='released_at'
                    required
                  >
                    <DatePicker
                      name='released_at'
                      showToday
                      showTime={useDatePickerTime
                        ? { defaultValue: moment(DEFAULT_RELEASE_TIME, 'HH:mm:ss') }
                        : false}
                      disabledDate={(date) => date.isBefore(moment(collectionDetails?.released_at))}
                      value={values?.released_at ? moment(values.released_at).utc(false) : null}
                      onChange={async (date, dateString) => {
                        await setFieldValue('released_at', !isEmpty(dateString) ? moment(dateString).utc(true) : '');
                      }}
                      onBlur={async ({ target }) => {
                        await setFieldTouched('released_at', true);
                        await onBlur('released_at', !isEmpty(target.value) ? moment(target.value).utc(true) : '', setFieldValue);
                      }}
                    />
                  </FormItem>
                </Col>
              )}

              <Col span={24}>
                <FormItem
                  name='use_utc_zero_time'
                  label={
                    <Row className='w-full' justify="space-between" align="middle">
                      <IntlMessages id="features-use-utc" />
                      <Tooltip
                        placement="top"
                        title={
                          <>
                            <IntlMessages id="features-use-utc-info" />
                            <div>
                              <Link to='https://x-flow-ltd.atlassian.net/wiki/spaces/HCD/pages/734461960/Use+UTC+0' target="_blank">
                                <u><IntlMessages id="ui-general-documentation-link" /></u>
                              </Link>
                            </div>
                          </>
                        }
                      >
                        <InfoCircleOutlined className='hp-ml-8' />
                      </Tooltip>
                    </Row>
                  }
                >
                  <Switch
                    name="use_utc_zero_time"
                  />
                </FormItem>
              </Col>

              <Row gutter={[ 16, 16 ]} justify='end'>
                <Col>
                  <ResetButton
                    loading={isUpdatingCollectionImage || isAttaching}
                    onClick={handleReset}
                  >
                    <IntlMessages id='ui-general-reset' />
                  </ResetButton>
                </Col>
                <Col>
                  <SubmitButton
                    loading={isUpdatingCollectionImage || isAttaching}
                    disabled={!isValid}
                  >
                    <IntlMessages id="ui-general-save" />
                  </SubmitButton>
                </Col>
              </Row>
            </Spinner>
          </Form>
        );
      }}
    </Formik>
  );
};

UpdateCollectionImageModalForm.propTypes = {
  imageId: PropTypes.number,
  collectionDetails: PropTypes.object,
  initialValues: PropTypes.object,
  onModalClose: PropTypes.func,
  isImageAttaching: PropTypes.bool,
};

export default UpdateCollectionImageModalForm;
