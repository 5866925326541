const feeds = {
  'feeds-breadcrumbs-list': 'Feeds',
  'feeds-breadcrumbs-released-list': 'Released',
  'feeds-breadcrumbs-archived-list': 'Archive',
  'feeds-breadcrumbs-trash-list': 'Trash',
  'feeds-form-very-simple-difficulty': 'Very simple',
  'feeds-form-simple-difficulty': 'Simple',
  'feeds-form-medium-difficulty': 'Medium',
  'feeds-form-hard-difficulty': 'Hard',
  'feeds-form-difficulty-tooltip': 'Current options predefine taps min/max values',
  'feeds-list-unpublish-disabled-tooltip': 'Cannot unpublish image, because image is attached to collection or/and to editor\'s choice data.',
  'feeds-trash-modal-remove-text': 'Attention! The picture will be completely deleted, it cannot be found in the panel and cannot be used.',
  'feeds-archive-modal-trash-text': 'Image will be trashed, are you sure you want to proceed?',
  'feeds-trash-modal-return-to-draft-confirm': 'Are you sure you want to return image to draft?',
  'feeds-unpublish-modal-trash-confirm': 'Are you sure you want to trash image?',
  'feeds-unpublish-modal-archive-confirm': 'Are you sure you want to archive image?',
  'feeds-modal-unpublish-archive-text': 'If you click on the Archive, the picture will be unpublished, but will remain available for synchronization.',
  'feeds-modal-unpublish-trash-text': 'If you click the Trash button, the picture will be completely unpublished and can be deleted.',
  'feeds-modal-unpublish-image-in-prod': 'Image is in production for all or part of the world.',
  'feeds-modal-edit-image-save-and-publish-info': 'We inform you about the availability of updates for the Happy Color mobile app. To successfully publish these changes, click on the \'Save & Publish\' button.',
  'feeds-modal-edit-image-save-info': 'We inform you about the availability of updates for the Happy Color mobile app. To successfully publish these changes, click on the \'Save\' button.',
};

export default feeds;
