import React, { useCallback, useState } from 'react';
import { Card, Row, Spin, Tag, Tooltip } from 'antd';
import { PiHandTap } from 'react-icons/pi';
import { HiMiniPaintBrush } from 'react-icons/hi2';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import capitalize from 'lodash/capitalize';
import { TfiCommentAlt } from 'react-icons/tfi';
import appColors from '../../../../const/colors';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import urlPageCategoryDetails from '../../../../urls/urlPageCategoryDetails';
import useElementSize from '../../../../app/hooks/useElementSize';
import mapImageTypeToColor from '../../../../lib/mapImageTypeToColor';
import ImageBadge from '../ImageBadge';
import ContentCardTags from '../ContentCardTags';
import CollapsibleText from '../../../../components/CollapsibleText';
import BeeLink from '../BeeLink';
import { EMPTY_IMAGE_PLACEHOLDER, EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



const ContentCardDetails = ({
  data,
  isMainCategory,
  actions,
  showContour,
  handleClick,
  lazyLoadStyles,
}) => {
  const [ isLoading, setLoading ] = useState(true);
  const [ elementRef, size ] = useElementSize();
  const mainCategory = data.categories.data.filter((item) => item.is_main)[0];

  return (
    <Card
      className={`content-card content-image ${isMainCategory ? 'content-card__main-category' : ''}`}
      actions={actions}
      cover={
        <div ref={elementRef} className="hp-position-relative">
          <Spin spinning={isLoading}>
            <LazyLoadImage
              onLoad={() => {
                setLoading(false);
              }}
              style={lazyLoadStyles}
              placeholderSrc={EMPTY_IMAGE_PLACEHOLDER}
              effect='blur'
              alt="picture preview"
              className='hp-cursor-pointer'
              src={data[showContour ? 'icon' : 'preview'].data.path}
              onClick={handleClick}
            />
          </Spin>

          <ContentCardTags
            isLabel
            items={data.labels ?? []}
            cardSize={size}
          />

          <ImageBadge image={data} className='hp-mt-32' />
        </div>
      }
    >
      <div className="hp-d-flex content-card__tags">
        <Tag color={appColors.lightIndigo}>
          {`ID: ${data?.id ?? EMPTY_VALUE_PLACEHOLDER}`}
        </Tag>
        <Tag className="hp-mr-0" color={mapImageTypeToColor(data.source.data.type_content)}>
          {capitalize(data.source.data.type_content)}
        </Tag>
      </div>
      <div className="hp-d-flex content-card__metadata">
        <BeeLink
          link={data.source.data.link}
          title={data.source.data.source_item_id}
        />
        <div className="hp-d-flex hp-align-items-center">
          <Tooltip placement="top" title={<IntlMessages id="content-image-taps" />}>
            <span className="content-stat-info">
              <PiHandTap className='hp-mr-4' />
              {data?.data?.data[0]?.taps ?? EMPTY_VALUE_PLACEHOLDER}
            </span>
          </Tooltip>
          /
          <Tooltip placement="top" title={<IntlMessages id="content-image-colors-amount" />}>
            <span className="content-stat-info">
              <HiMiniPaintBrush className='hp-mr-4' />
              {data?.data?.data[0]?.colors ?? EMPTY_VALUE_PLACEHOLDER}
            </span>
          </Tooltip>
        </div>
      </div>

      <Row justify="space-between">
        <div className='hp-d-flex content-card__metadata-footer'>
          {mainCategory && (
            <Link to={urlPageCategoryDetails({ categoryId: mainCategory?.id })} target="_blank">
              <CollapsibleText
                text={mainCategory.name}
                maxLength={!data.comment ? '20' : '15'}
                size='13px'
              />
            </Link>
          )}
        </div>

        <div className='hp-d-flex content-card__metadata-footer'>
          {data.comment && (
            <Tooltip placement="top" title={data.comment}>
              <div className='hp-d-flex hp-align-items-center hp-cursor-pointer'>
                <TfiCommentAlt size={16} />
              </div>
            </Tooltip>
          )}
        </div>
      </Row>
    </Card>
  );
};

const ContentCard = ({
  data,
  showContour = false,
  onClick,
  actions,
  useTooltip = false,
}) => {
  const intl = useIntl();
  const { categoryId } = useParams();

  const isMainCategory = data?.categories.data?.find((category) => category.id === Number.parseInt(categoryId, 10))?.is_main;
  const lazyLoadStyles = { minHeight: '250px', objectFit: 'cover', cursor: 'pointer' };

  const handleClick = useCallback(() => {
    onClick(data);
  }, [ onClick ]);

  return (
    <div>
      {useTooltip ? (
        <Tooltip key="download" placement="top" title={<IntlMessages id='ui-general-attach-image-click' />}>
          <ContentCardDetails
            intl={intl}
            data={data}
            isMainCategory={isMainCategory}
            actions={actions}
            showContour={showContour}
            handleClick={handleClick}
            lazyLoadStyles={lazyLoadStyles}
          />
        </Tooltip>
      ) : (
        <ContentCardDetails
          intl={intl}
          data={data}
          isMainCategory={isMainCategory}
          actions={actions}
          showContour={showContour}
          handleClick={handleClick}
          lazyLoadStyles={lazyLoadStyles}
        />
      )}
    </div>
  );
};

ContentCardDetails.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.array,
  showContour: PropTypes.bool,
  lazyLoadStyles: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  useTooltip: PropTypes.bool,
  isMainCategory: PropTypes.number,
};

ContentCard.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.array,
  showContour: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  useTooltip: PropTypes.bool,
};

export default ContentCard;
