import React from 'react';
import { Row } from 'antd';
import PropTypes from 'prop-types';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { HasRights } from '../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../const/permissions';
import ButtonDanger from '../../../../components/ButtonDanger';
import ButtonDraft from '../../../../components/ButtonDraft';
import { FEEDS_SOURCE_MAP, UPDATED_IMAGE_STATUSES } from '../../pages/Feeds/PageFeeds.const';



const ImageModalCardFooterRemove = ({ handleUpdateImageStatus, handleModalClose, feedSource, isLoading = false }) => {
  return (
    <Row key='remove'>
      <ButtonDraft
        className='feed-modal__button feed-modal__button--draft hp-mr-16'
        text={<IntlMessages id='ui-general-cancel' />}
        onClick={() => handleModalClose()}
      />
      {HasRights([ Permissions.CONTENT.FEEDS.IMAGE.SET_DELETE ]) && (
        <ButtonDanger
          loading={isLoading}
          className='feed-modal__button feed-modal__button--danger'
          text={<IntlMessages id='ui-general-remove' />}
          onClick={() => handleUpdateImageStatus(
            feedSource === FEEDS_SOURCE_MAP.ARCHIVED
              ? UPDATED_IMAGE_STATUSES.TRASH
              : UPDATED_IMAGE_STATUSES.DELETED,
          )}
        />
      )}
    </Row>
  );
};

ImageModalCardFooterRemove.propTypes = {
  handleUpdateImageStatus: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  feedSource: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

export default ImageModalCardFooterRemove;
