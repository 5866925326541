import React, { useEffect, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { skipToken } from '@reduxjs/toolkit/query';
import { DATE_FORMAT } from '../../../../../const/system';
import useToggle from '../../../../../app/hooks/useToggle';
import useInfiniteScroll from '../../../../../app/hooks/useInfiniteScroll';
import getSourceFromUrl from '../../../../../lib/getSourceFromUrl';
import encodeUnsafeCharacters from '../../../../../lib/encodeUnsafeCharacters';
import FeedsLayout from '../../../components/FeedsLayout';
import {
  CONTENT_TYPE_MAP,
  searchFilterFields,
  getTypeContentGradient,
  getTypeContentRedirectUrl,
  MODAL_MODE,
} from '../PageFeeds.const';
import { useGetFeedsQuery } from '../../../api/feedsApiSlice';



const PageFeedsReleased = () => {
  const { pathname } = useLocation();
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ isVisibleFilterSidebar, toggleFilterSidebar ] = useToggle();
  const [ previewModalOpen, togglePreviewModal ] = useToggle();
  const [ showContour, toggleShowContour ] = useToggle();
  const [ previewImage, setPreviewImage ] = useState({});
  const [ queryParams, setQuery ] = useState('');
  const [ modalMode, setModalMode ] = useState(MODAL_MODE.VIEW);
  const [ isRefetch, setIsRefetch ] = useState(false);
  const [ isQueryReady, setIsQueryReady ] = useState(false);
  const sessionId = useRef(Date.now()).current; //hack to reset cache and do force re-fetch

  const feedSource = getSourceFromUrl(pathname);
  const prevSourceRef = useRef(feedSource);

  const { data: {
    data,
    pagination,
  } = { data: [] },
  isFetching,
  error: formErrors = {},
  } = useGetFeedsQuery(isQueryReady ? { feedSource, queryParams, sessionId } : skipToken);

  const {
    groupedItemsList,
    itemsLength,
    hasMore,
    handleLoadNextData,
    initFilterValues,
    setInitFilterValues,
    combinedQueryParams,
    resetIsManuallyTriggered,
    setResetManuallyTriggered,
  } = useInfiniteScroll({
    data,
    pagination,
    searchFilterFields,
    source: feedSource,
    isRefetch,
  });

  const isLoading = isFetching;

  const handleSetFilterToCurrentDate = () => {
    setInitFilterValues({ released_to: moment().format(DATE_FORMAT) });
  };

  useEffect(() => {
    const prevSource = prevSourceRef.current;

    if (!resetIsManuallyTriggered || prevSource !== feedSource) {
      prevSourceRef.current = feedSource;

      if (!isEmpty(initFilterValues) && prevSource === feedSource) {
        const searchQuery = Object.entries(initFilterValues)
          .map(([ key, value ]) => `${key}:${encodeUnsafeCharacters(value)}`)
          .join(';');

        setSearchParams({ search: searchQuery }, { replace: true });
      } else {
        handleSetFilterToCurrentDate();
      }
    }
  }, [ initFilterValues, resetIsManuallyTriggered, feedSource ]);

  useEffect(() => {
    if (!isEmpty(combinedQueryParams) || combinedQueryParams === '') {
      if (combinedQueryParams) {
        setQuery(combinedQueryParams);
      }

      if (combinedQueryParams === '') {
        setQuery('');
      }

      if (!isQueryReady) {
        setIsQueryReady(true);
      }
    }
  }, [ combinedQueryParams, isQueryReady ]);

  const handleCardClick = (item) => {
    setPreviewImage(item);
    setModalMode(MODAL_MODE.VIEW);
    togglePreviewModal();
  };

  const handlePreviewModalClose = () => {
    togglePreviewModal();
  };

  const handleChangeModalMode = (mode) => {
    setModalMode(mode);
  };

  const actions = (item) => {
    if (item.source_type) {
      return [ (
        <div key='source'>
          {item.source_id && item.source_type !== CONTENT_TYPE_MAP.FEED ? (
            <Link to={getTypeContentRedirectUrl(item.source_type, item.source_id)} target="_blank">
              <div
                className="card-type-content-source-action"
                style={{ background: getTypeContentGradient(item.source_type) }}
              >
                {item.source_type}
              </div>
            </Link>
          ) : (
            <div
              className="card-type-content-source-action"
              style={{ background: getTypeContentGradient(item.source_type), cursor: 'default' }}
            >
              {item.source_type}
            </div>
          )}
        </div>
      ) ];
    }

    return [];
  };

  return (
    <FeedsLayout
      feedSource={feedSource}
      modalMode={modalMode}
      previewModalOpen={previewModalOpen}
      handlePreviewModalClose={handlePreviewModalClose}
      previewImage={previewImage}
      setIsRefetch={setIsRefetch}
      handleChangeModalMode={handleChangeModalMode}
      isFetching={isFetching}
      formErrors={formErrors}
      initFilterValues={initFilterValues}
      handleSetFilterToCurrentDate={handleSetFilterToCurrentDate}
      searchQueryParams={searchQueryParams}
      setSearchParams={setSearchParams}
      setInitFilterValues={setInitFilterValues}
      setResetManuallyTriggered={setResetManuallyTriggered}
      resetIsManuallyTriggered={resetIsManuallyTriggered}
      isLoading={isLoading}
      groupedItemsList={groupedItemsList}
      itemsLength={itemsLength}
      handleLoadNextData={handleLoadNextData}
      hasMore={hasMore}
      actions={actions}
      handleCardClick={handleCardClick}
      showContour={showContour}
      toggleShowContour={toggleShowContour}
      isVisibleFilterSidebar={isVisibleFilterSidebar}
      toggleFilterSidebar={toggleFilterSidebar}
    />
  );
};

export default PageFeedsReleased;
