import React, { useEffect, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useLocation, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { skipToken } from '@reduxjs/toolkit/query';
import { DATE_FORMAT } from '../../../../../const/system';
import { HasRights } from '../../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../../const/permissions';
import encodeUnsafeCharacters from '../../../../../lib/encodeUnsafeCharacters';
import useToggle from '../../../../../app/hooks/useToggle';
import useInfiniteScroll from '../../../../../app/hooks/useInfiniteScroll';
import getSourceFromUrl from '../../../../../lib/getSourceFromUrl';
import ButtonDanger from '../../../../../components/ButtonDanger';
import FeedsLayout from '../../../components/FeedsLayout';
import {
  searchFilterFields,
  MODAL_MODE,
} from '../PageFeeds.const';
import { useGetFeedsQuery } from '../../../api/feedsApiSlice';



const PageFeedsList = () => {
  const { pathname } = useLocation();
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ isVisibleFilterSidebar, toggleFilterSidebar ] = useToggle();
  const [ previewModalOpen, togglePreviewModal ] = useToggle();
  const [ showContour, toggleShowContour ] = useToggle();
  const [ previewImage, setPreviewImage ] = useState({});
  const [ queryParams, setQuery ] = useState('');
  const [ modalMode, setModalMode ] = useState(MODAL_MODE.VIEW);
  const [ isRefetch, setIsRefetch ] = useState(false);
  const [ isQueryReady, setIsQueryReady ] = useState(false);
  const sessionId = useRef(Date.now()).current; //hack to reset cache and do force re-fetch

  const feedSource = getSourceFromUrl(pathname);
  const prevSourceRef = useRef(feedSource);

  const { data: {
    data,
    pagination,
  } = { data: [] },
  isFetching,
  error: formErrors = {},
  } = useGetFeedsQuery(isQueryReady ? { feedSource, queryParams, sessionId } : skipToken);


  const {
    groupedItemsList,
    itemsLength,
    hasMore,
    handleLoadNextData,
    initFilterValues,
    setInitFilterValues,
    combinedQueryParams,
    resetIsManuallyTriggered,
    setResetManuallyTriggered,
  } = useInfiniteScroll({
    data,
    pagination,
    searchFilterFields,
    source: feedSource,
    isRefetch,
  });

  const isLoading = isFetching;

  const handleSetFilterToCurrentDate = () => {
    setInitFilterValues({ released_to: moment().format(DATE_FORMAT) });
  };

  useEffect(() => {
    const prevSource = prevSourceRef.current;

    if (!resetIsManuallyTriggered || prevSource !== feedSource) {
      prevSourceRef.current = feedSource;

      if (!isEmpty(initFilterValues) && prevSource === feedSource) {
        const searchQuery = Object.entries(initFilterValues)
          .map(([ key, value ]) => `${key}:${encodeUnsafeCharacters(value)}`)
          .join(';');

        setSearchParams({ search: searchQuery }, { replace: true });
      } else {
        handleSetFilterToCurrentDate();
      }
    }
  }, [ initFilterValues, resetIsManuallyTriggered, feedSource ]);

  useEffect(() => {
    if (!isEmpty(combinedQueryParams) || combinedQueryParams === '') {
      if (combinedQueryParams) {
        setQuery(combinedQueryParams);
      }

      if (combinedQueryParams === '') {
        setQuery('');
      }

      if (!isQueryReady) {
        setIsQueryReady(true);
      }
    }
  }, [ combinedQueryParams, isQueryReady ]);

  const handleCardClick = (item) => {
    setPreviewImage(item);
    setModalMode(MODAL_MODE.VIEW);
    togglePreviewModal();
  };

  const handlePreviewModalClose = () => {
    togglePreviewModal();
  };

  const handleChangeModalMode = (mode) => {
    setModalMode(mode);
  };

  const actions = (item) => {
    return [
      HasRights([
        Permissions.CONTENT.FEEDS.IMAGE.SET_ARCHIVED,
        Permissions.CONTENT.FEEDS.IMAGE.SET_TRASHED,
        Permissions.CONTENT.FEEDS.IMAGE.SET_DRAFT,
      ]) && (
        <ButtonDanger
          disabled={!isEmpty(item?.used_in_collections) || !isEmpty(item?.used_in_editor_choices)}
          className='btn-danger'
          key='unpublish'
          onClick={(event) => {
            event.target?.blur();
            setPreviewImage(item);
            setModalMode(MODAL_MODE.UNPUBLISH);
            togglePreviewModal();
          }}
          hasTooltip={!isEmpty(item?.used_in_collections) || !isEmpty(item?.used_in_editor_choices)}
        />
      ),
    ];
  };

  return (
    <FeedsLayout
      feedSource={feedSource}
      modalMode={modalMode}
      previewModalOpen={previewModalOpen}
      handlePreviewModalClose={handlePreviewModalClose}
      previewImage={previewImage}
      setIsRefetch={setIsRefetch}
      handleChangeModalMode={handleChangeModalMode}
      isFetching={isFetching}
      formErrors={formErrors}
      initFilterValues={initFilterValues}
      handleSetFilterToCurrentDate={handleSetFilterToCurrentDate}
      searchQueryParams={searchQueryParams}
      setSearchParams={setSearchParams}
      setInitFilterValues={setInitFilterValues}
      setResetManuallyTriggered={setResetManuallyTriggered}
      resetIsManuallyTriggered={resetIsManuallyTriggered}
      isLoading={isLoading}
      groupedItemsList={groupedItemsList}
      itemsLength={itemsLength}
      handleLoadNextData={handleLoadNextData}
      hasMore={hasMore}
      actions={actions}
      handleCardClick={handleCardClick}
      showContour={showContour}
      toggleShowContour={toggleShowContour}
      isVisibleFilterSidebar={isVisibleFilterSidebar}
      toggleFilterSidebar={toggleFilterSidebar}
    />
  );
};

export default PageFeedsList;
