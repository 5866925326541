import React, { useCallback, useState } from 'react';
import { Row } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { HasRights } from '../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../const/permissions';
import Spinner from '../../../../components/Spinner';
import getInitialImageCategoriesValues from '../../../../lib/getInitialImageCategoriesValues';
import { validationSchema } from '../../forms/FormEditImage/FormEditImage.const';
import { FEEDS_SOURCE_MAP, MODAL_MODE } from '../../pages/Feeds/PageFeeds.const';
import handleResponse from '../../../../lib/handleResponse';
import transformErrors from '../../../../lib/transformErrors';
import modifyImageCategoriesValues from '../../../../lib/modifyImageCategoriesValues';
import ImageModalCardBodyView from '../ImageModalCardBodyView';
import ImageModalCardBodyUnpublish from '../ImageModalCardBodyUnpublish';
import ImageModalCardBodyRemove from '../ImageModalCardBodyRemove';
import getSourceFromUrl from '../../../../lib/getSourceFromUrl';
import ImageModalCardBodyEdit from '../ImageModalCardBodyEdit';
import ImageModalCardFooterEdit from '../ImageModalCardFooterEdit';
import ImageModalCardFooterUnpublish from '../ImageModalCardFooterUnpublish';
import ImageModalCardFooterRemove from '../ImageModalCardFooterRemove';
import ImageModalCardFooterView from '../ImageModalCardFooterView';
import { useUpdateImageMutation, useUpdateImageStatusMutation } from '../../api/imagesApiSlice';
import { useGetCategoriesQuery } from '../../api/categoriesApiSlice';
import { useGetTagsQuery } from '../../api/tagsApiSlice';
import { imageStatuses } from '../../../../const/imageStatuses';



const ImageModalCard = ({
  data,
  handleModalClose,
  handleRefetch = (_needRefetch) => {},
  handleModalMode = (_mode) => {},
  modalMode = MODAL_MODE.VIEW,
  isFeeds = false,
}) => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const feedSource = getSourceFromUrl(pathname);
  const [ imageEditInitialErrors, setImageEditInitialErrors ] = useState({});

  const [ updateImageStatus, { isLoading: isImageStatusUpdating } ] = useUpdateImageStatusMutation();
  const [ updateImage, { isLoading: isImageUpdating } ] = useUpdateImageMutation();

  const { data: {
    data: categories = [],
  } = { data: [], pagination: {} }, isFetching: isCategoriesFetching } = useGetCategoriesQuery({ queryParams: 'limit=0' });

  const { data: {
    data: hashtags = [],
  } = { data: [], pagination: {} }, isFetching: isHashtagsFetching } = useGetTagsQuery({ queryParams: 'limit=0' });

  const handleUpdateImageStatus = (status) => {
    handleRefetch(false);

    updateImageStatus({ id: data.id, status })
      .unwrap()
      .then(() => handleModalClose())
      .then(() => handleResponse(
        'success',
        intl,
        'ui-general-updated-successfully',
        [ () => handleRefetch(true) ],
      ))
      .catch((error) => handleResponse('error', intl, 'ui-general-update-failed', [], error));
  };

  const handleUpdateImage = (values) => {
    handleRefetch(false);

    updateImage({ id: data.id, payload: values })
      .unwrap()
      .then(() => handleModalClose())
      .then(() => handleResponse(
        'success',
        intl,
        'ui-general-updated-successfully',
        [ () => handleRefetch(true) ],
      ))
      .catch((error) => handleResponse(
        'error',
        intl,
        'ui-general-update-failed',
        [ () => setImageEditInitialErrors(transformErrors(error?.data?.errors)) ],
        error,
      ));
  };

  const getModalBodyByModalMode = () => {
    switch (modalMode) {
      case MODAL_MODE.VIEW:
        return (
          <ImageModalCardBodyView data={data} />
        );
      case MODAL_MODE.EDIT:
        return (
          <ImageModalCardBodyEdit
            data={data}
            categories={categories}
            hashtags={hashtags}
          />
        );
      case MODAL_MODE.UNPUBLISH:
        return (
          <ImageModalCardBodyUnpublish
            data={data}
            preview={data?.preview?.data?.path}
          />
        );
      case MODAL_MODE.REMOVE:
        return (
          <ImageModalCardBodyRemove
            data={data}
            removeText={feedSource === FEEDS_SOURCE_MAP.TRASHED
              ? <IntlMessages id='feeds-trash-modal-remove-text' />
              : <IntlMessages id='feeds-archive-modal-trash-text' />}
          />
        );
      default:
        return (
          <ImageModalCardBodyView data={data} />
        );
    }
  };

  const getModalFooterByModalMode = useCallback(() => {
    if (modalMode === MODAL_MODE.EDIT && HasRights([ Permissions.CONTENT.FEEDS.IMAGE.UPDATE ])) {
      return (
        <ImageModalCardFooterEdit
          isLoading={isImageUpdating}
          isSaveAndPublish={[
            imageStatuses.release_on,
            imageStatuses.bonus,
            imageStatuses.achievement,
            imageStatuses.daily,
            imageStatuses.pack,
          ].includes(data.status)}
        />
      );
    }

    if (modalMode === MODAL_MODE.UNPUBLISH) {
      return (
        <ImageModalCardFooterUnpublish
          handleUpdateImageStatus={handleUpdateImageStatus}
          releaseDate={data.release_date}
        />
      );
    }

    if (modalMode === MODAL_MODE.REMOVE) {
      return (
        <ImageModalCardFooterRemove
          handleUpdateImageStatus={handleUpdateImageStatus}
          isLoading={isImageStatusUpdating}
          feedSource={feedSource}
          handleModalClose={handleModalClose}
        />
      );
    }

    if (modalMode === MODAL_MODE.VIEW) {
      return (
        <ImageModalCardFooterView
          data={data}
          handleModalMode={handleModalMode}
          handleUpdateImageStatus={handleUpdateImageStatus}
          feedSource={feedSource}
          isFeeds={isFeeds}
        />
      );
    }

    return undefined;
  }, [ modalMode, data, feedSource ]);

  const isLoading = isCategoriesFetching || isHashtagsFetching || isImageStatusUpdating || isImageUpdating;

  const isRightAlignedModal = feedSource === FEEDS_SOURCE_MAP.TRASHED
    || [ MODAL_MODE.EDIT, MODAL_MODE.REMOVE ].includes(modalMode)
    || ([ FEEDS_SOURCE_MAP.RELEASED ].includes(feedSource) && data.source_type !== 'feed' && modalMode === MODAL_MODE.VIEW)
    || !isFeeds;

  return (
    <Spinner spinning={isLoading}>
      <Formik
        initialValues={{
          comment: data?.comment,
          labels: data?.labels,
          hashtags: data?.tags?.data?.map((tag) => tag.id),
          ...getInitialImageCategoriesValues(data),
        }}
        initialErrors={imageEditInitialErrors}
        validationSchema={validationSchema}
        enableReinitialize
        isSubmitting={isImageUpdating}
        onSubmit={async (values) => {
          await handleUpdateImage({
            comment: !isEmpty(values?.comment) ? values.comment.trim() : '',
            labels: values.labels,
            hashtags: values.hashtags,
            categories: modifyImageCategoriesValues(values),
          });
        }}
      >
        {() => {
          return (
            <>
              {getModalBodyByModalMode()}
              <Row
                justify={isRightAlignedModal ? 'end' : 'space-between'}
                className='hp-pt-16 hp-border-top-1'
              >
                {getModalFooterByModalMode()}
              </Row>
            </>
          );
        }}
      </Formik>
    </Spinner>
  );
};

ImageModalCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.any,
    icon: PropTypes.object,
    preview: PropTypes.object,
    created_at: PropTypes.string,
    status: PropTypes.string,
    data: PropTypes.object,
    source: PropTypes.object,
    categories: PropTypes.object,
    used_in_collections: PropTypes.array,
    used_in_editor_choices: PropTypes.array,
    release_date: PropTypes.string,
    source_type: PropTypes.string,
    comment: PropTypes.string,
    labels: PropTypes.array,
    tags: PropTypes.object,
  }),
  modalMode: PropTypes.string,
  handleModalClose: PropTypes.func.isRequired,
  handleRefetch: PropTypes.func,
  handleModalMode: PropTypes.func,
  isFeeds: PropTypes.bool,
};

export default ImageModalCard;
